/**
 * Log an event to Google Analytics
 * @param {string}  eventType   Event type to log as (incl. Google-specified event types).
 * @param {object}  payload     Payload object of parameters to display in GA dash. Don't pass secrets!
 */
export const logEventGA = (eventType, payload) => {
  if (window.gtag !== undefined && typeof window !== undefined) {
    window.gtag("event", eventType, payload)
  } else {
    console.log(`>>> err levent ${eventType}`)
  }
}
