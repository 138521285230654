import React from "react"
import PropTypes from "prop-types"
import ButtonCustom from "../ButtonCustom"
import Icon from "../Icon"
import { DONATION_TYPES } from "../../constants/donation-types"
import { formatStringJoin, formatMoney, formatMisc } from "../../utils/format"
import { navigateToFamily } from "../../utils"
import { logEventGA } from "../../utils/log"

import styles from "./styles.module.scss"

const CardFamily = ({ data, isClosed = false }) => {
  const { family_id, display_name, location_text, size, organization } = data
  const needs = data.needs
  const randomNeed = needs[Math.floor(Math.random() * needs.length)]

  const getPreviousNeedsTotal = () =>
    needs &&
    needs.reduce((acc, need) => {
      return acc + need.value
    }, 0)

  const getNeedsSummary = () =>
    needs &&
    needs.reduce((acc, need) => {
      if (acc.indexOf(need.needTypeByNeedType.display_name) < 0)
        acc.push(need.needTypeByNeedType.display_name)
      return acc
    }, [])

  const handleClick = () => {
    logEventGA("view_family", {
      id: family_id,
      display_name,
      location_text,
      size,
      organization,
    })
    navigateToFamily({ id: family_id })
  }

  let description
  const subject = display_name
  if (isClosed) {
    description = `
      ${subject} needed ${formatMoney(getPreviousNeedsTotal())}
      for ${formatStringJoin(getNeedsSummary()).toLowerCase()}
    `
  } else {
    description = `
      ${subject} needs help with ${formatStringJoin(
      getNeedsSummary()
    ).toLowerCase()}
    `
  }
  var svgComponent = null
  var needValue = 30

  if (randomNeed) {
    needValue = randomNeed.value
    description = `
      ${subject} needs help with ${formatMisc(
      randomNeed.needTypeByNeedType.display_name.toLowerCase()
    )}
    `
    switch (randomNeed.need_type) {
      case "HOUSING": {
        svgComponent = <img alt="Housing" src={"/needsIcons/Housing.svg"} />
        break
      }
      case "BILLS": {
        svgComponent = <img alt="Bills" src={"/needsIcons/Bills.svg"} />
        break
      }
      case "CHILDCARE": {
        svgComponent = <img alt="Childcare" src={"/needsIcons/Childcare.svg"} />
        break
      }
      case "EDUCATION": {
        svgComponent = <img alt="Education" src={"/needsIcons/Education.svg"} />
        break
      }
      case "FURNISHINGS": {
        svgComponent = (
          <img alt="Furnishings" src={"/needsIcons/Furnishings.svg"} />
        )
        break
      }
      case "MISC": {
        svgComponent = <img alt="Misc" src={"/needsIcons/Misc.svg"} />
        break
      }
      case "TRANSPORT": {
        svgComponent = (
          <img alt="Transportation" src={"/needsIcons/Transportation.svg"} />
        )
        break
      }
      case "GROCERIES": {
        svgComponent = <img alt="Groceries" src={"/needsIcons/Groceries.svg"} />
        break
      }
      case "MEDICAL": {
        svgComponent = <img alt="Medical" src={"/needsIcons/Medical.svg"} />
        break
      }
      case "CLOTHING": {
        svgComponent = <img alt="Clothing" src={"/needsIcons/Clothing.svg"} />
        break
      }
      default: {
        svgComponent = <img alt="Misc" src={"/needsIcons/Misc.svg"} />
        break
      }
    }
  }

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardInner} onClick={handleClick}>
        <div className={styles.cardTitleRow}>
          <p className={styles.cardTitle}>{description}</p>
          <div className={styles.cardTitleRowSvg}>{svgComponent}</div>
        </div>

        <div className={styles.cardSubtitleRow}>
          <p className={styles.cardSubtitle}>
            {data.situation.substring(0, 70)}
            {data.situation.length > 70 ? "..." : null}
          </p>
        </div>

        <div className={styles.cardPriceRow}>
          <p className={styles.cardPrice}>${needValue}</p>
        </div>

        <div className={styles.donateRow}>
          <button className={styles.donateBtn}>Donate Now</button>
        </div>
      </div>

      <div className={styles.cardFooter}>
        <p className={styles.cardFooterText}>{data.organization.region.name}</p>
        <p className={styles.cardFooterTextSpace}>|</p>
        <p className={styles.cardFooterText}>{data.organization.name}</p>
      </div>
    </div>
    // <article // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    //   className={styles.card}
    //   onClick={handleClick}
    //   onMouseEnter={handleMouseOver}
    //   onMouseLeave={handleMouseExit}
    // >
    //   {needs && (
    //     <>
    //       <Icon
    //         aesthetic={isClosed ? "simple" : "complex"}
    //         icon={
    //           isClosed
    //             ? DONATION_TYPES.OTHER
    //             : DONATION_TYPES[getFamilyIcon()] || DONATION_TYPES.OTHER
    //         }
    //       />
    //       <p className={`tiny-body-copy ${styles.header}`}>
    //         <span className={styles.headerElement}>{location_text}</span>
    //         <span className={styles.headerElement}>{organization.name}</span>
    //       </p>
    //       <p className={`small-header ${isClosed && styles.light}`}>
    //         {display_name}
    //       </p>
    //       <p
    //         className={`small-body-copy ${styles.description} ${isClosed &&
    //           styles.light}`}
    //       >
    //         {description}
    //       </p>
    //       <div className={styles.button}>
    //         <ButtonCustom
    //           size="medium"
    //           handleClick={handleClick}
    //           hierarchy={isMouseOverToggled ? "primary" : "secondary"}
    //           hiddenByDefault
    //           role="button"
    //         >
    //           {!isClosed ? `Share with ${display_name}` : `Learn more`}
    //         </ButtonCustom>
    //       </div>
    //     </>
    //   )}
    // </article>
  )
}

CardFamily.defaultProps = {
  data: {
    icon: DONATION_TYPES.OTHER,
  },
}

CardFamily.propTypes = {
  data: PropTypes.shape({
    display_name: PropTypes.string,
    location_text: PropTypes.string,
    members: PropTypes.string,
    size: PropTypes.number,
    situation: PropTypes.string,
    icon: PropTypes.string,
    needs: PropTypes.array,
  }),
}

export default CardFamily
