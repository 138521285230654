import React from "react"
import styles from "./styles.module.scss"

const GridSection = ({
  children,
  filters,
  description,
  title,
  bgColor,
  wrapperClassName,
  ...other
}) => {
  const bgStlye = {
    backgroundColor: `var(--color-${bgColor})`,
  }
  return (
    <section style={bgStlye} {...other}>
      <div className={`wrapper ${wrapperClassName}`}>
        {title && <h2 className={`large-header ${styles.title}`}>{title}</h2>}
        {description && (
          <p className={`small-body-copy ${styles.description}`}>
            {description}
          </p>
        )}
        {filters && <div className={styles.filters}>{filters}</div>}
        <div className={`no-list ${styles.grid}`}>{children}</div>
      </div>
    </section>
  )
}

export default GridSection
